/** @format */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 300px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #e7e7e7;
  border-right: 1.1em solid #e7e7e7;
  border-bottom: 1.1em solid #e7e7e7;
  border-left: 1.1em solid rgb(119, 113, 236);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dwollaMain {
  margin: 4% 0px 0px 0px;
}

.bgimg {
  background-image: url("http://dslv9ilpbe7p1.cloudfront.net/KeMTgcQ05UZSq45TRvOB1Q_store_banner_image.jpeg");
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 5px;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}

/** @format */

#preview,
#bin {
  display: none;
}

.content {
  padding: 100px;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
}

.containerClass {
  margin: 30px 0;
  padding: 30px;
  width: 550px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #eaedf3;
  border-radius: 5px;
  background-color: #2d2d48;
  font-family: Verdana, Geneva, sans-serif;
}

iframe {
  height: 40px;
  width: 100%;
  border: unset;
  padding: 5px 3px;
  margin-top: 5px;
  font-family: Verdana, Geneva, sans-serif;
}

button {
  height: 40px;
  width: auto;
  padding: 0 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: #2e3745;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #aab7c8;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

button :hover {
  color: #000000;
}

#card-name-input {
  border: unset;
  width: 100%;
  outline: unset;
  margin-left: 3px;
  color: #04be5b;
  font-family: Verdana, Geneva, sans-serif;
}

.fake-field {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  font-family: Verdana, Geneva, sans-serif;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #eaedf3;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(31, 54, 78, 0.12);
  padding: 0 10px;
}

.field-wrapper {
  font-family: Verdana, Geneva, sans-serif;
}

#field-wrapper-address_line2 {
  width: 200px;
}

#field-wrapper-address_region {
  width: 150px;
}

#field-wrapper-address_postal_code {
  width: 120px;
}

#field-wrapper-expiration_date,
#field-wrapper-security_code {
  width: 150px;
}

#brand {
  height: auto;
  width: 124px;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0 7px;
  text-align: center;
}

#disclaimer-text {
  padding: 15px 0;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

#submit-form-button {
  width: 100%;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: #2d2d48;
}

#submit-form-button:hover {
  background-color: #be406c;
}

#result {
  display: none;
  color: #ffffff;
}
